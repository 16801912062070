import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import AOS from 'aos';

export class Layout extends Component {
    static displayName = Layout.name;

   
    componentDidMount() {
        AOS.init({duration: 2000})
    }
  render () {
    return (
        <div>
            <NavMenu />
       
          {this.props.children}
            <footer id="footer" class="footer">



                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong><span>MOUNTS</span></strong>. All Rights Reserved
                    </div>
                    <div class="credits">
                        Product Designed, Developed and Maintained by <a href="http://mounts.in/"> Mounts Software Service</a>

                    </div>
                </div>
            </footer>
      </div>
    );
  }
}
