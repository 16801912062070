import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class Productprimium extends Component {
    static displayName = Productprimium.name;

    constructor(props) {
        super(props);

        this.state = {
            toggle: true
        }

    }


    changehandle = () => {
        this.setState({
            toggle: !this.state.toggle
        })


    }

   
    render() {
        return (
            <>
            <section style={{ marginTop: 20 }} id="about" class="about">
                <header class="section-header">
                   
                        <p ><img src="assets/img/pricing-business.png" class="img-fluid" alt="" /> PREMIUM CARD</p>
                 
                   
                    </header>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>
                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Premium Features </h2>
                                <p>
                                   Premium Cards will have all the features of a gold card but with additional implementation executive support. The implementation executive will help you to create cards. In case of complex work or complete new card design the same will be charged as per the market.   </p>
                                

                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/hero2.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                </div>


                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">
                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/countimg.svg" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                <h2>CreditBack Points</h2>
                                <p>
                                        As soon as you register based on the volume of request our executive will set a call schedule. In case a call is getting delayed more than 48 hours you will be getting additional points. 
                                    </p>
                                </div>
                               </div>



                    </div>
                </div>

                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Platform Independent</h2>
                                <p>
                                        Zangie card is an platform independent Application and works on points. Do not have the required  points then all the paid services will not function. But as a Premium customer our executive will be available in 48 Hours.
</p>

                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/whyus.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                </div>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>
            </section>
            
                </>
        );
    }
}




