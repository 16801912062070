import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import './custom.css'
import Productdetails from './components/Productdetails';
import ProductGold from './components/ProductGold';
import Productprimium from './components/Productprimium';
import ProductCorporate from './components/ProductCorporate';




export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
            <Route exact path='/' component={Home} />
            <Route exact path='/Productdetails' component={Productdetails} />
            <Route exact path='/ProductCorporate' component={ProductCorporate} />
            <Route exact path='/ProductGold' component={ProductGold} />
            <Route exact path='/Productprimium' component={Productprimium} />
      </Layout>
    );
  }
}
