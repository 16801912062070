import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class ProductGold extends Component {
    static displayName = ProductGold.name;

    constructor(props) {
        super(props);

        this.state = {
            toggle: true
        }

    }


    changehandle = () => {
        this.setState({
            toggle: !this.state.toggle
        })


    }

   
    render() {
        return (
            <>
            <section style={{ marginTop: 20 }} id="about" class="about">
                <header class="section-header">

                        <p > <img style={{ marginLeft: 50 }} src="assets/img/pricing-starter.png" clss="img-fluid" alt="" />  GOLD CARD</p>
                    
                   
                    </header>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>
                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                            <div  class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Zangie Card</h2>
                                <p>
                                        Gold Cards will have all the features of Zangie. One can create a Zangie Card using the default template available. In case one has any specific design, the same can be integrated using zangie support ticket. A technical member will be associated to help the client based on the complexity of the requirement additional charges need to be paid along with support ticket charges.

   </p>
                                

                            </div>
                        </div>

                            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img style={{ height:'80%' }} src="assets/img/value1.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                </div>


                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">
                        
                        <div class="col-lg-12 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                <h2>Social Media</h2>
                                <p>
                                        The card link will be created and the same link can be shared over Whatsapp, Social media or any smart communication platform. It is an platform independent Application.
                                        Once you generate your card link the same can be shared over the internet and using a smart device.
 </p>  </div>
                        </div>



                    </div>
                </div>

                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Zangie Dashboard</h2>
                                <p>
                                        Smart Dashboard will display the details of users, to whom you have sent have opened the link or not. Once the user saves your Card, then the user will be getting free Card Keep and your card can be viewed from the card keep.</p>



                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/features-2.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                    </div>

                    <div class="container" data-aos="fade-up">
                        <div class="row gx-0">
                            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/values-1.png" class="img-fluid" alt="" />
                            </div>
                            <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                                <div class="content">

                                    <h2>Customers Details</h2>
                                    <p>
                                        Once the card is saved the user can view all the details you have saved. Users can make direct mobile calls and whatsapp as well as locate your address using pathfinder. You can update your product and services and the same can be viewed by the users.<br /><br />

                                        It is one unique platform which provides complete business information.

     </p>  </div>
                            </div>



                        </div>
                    </div>

                </section>
                <div class="container">
                    <a href="https://zangie.mounts.in/#pricing">
                        <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                    </a>
                </div>
            
                </>
        );
    }
}




