import React, { Component } from 'react';
import Price from './Price';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (<>
        <section id="hero" class="hero d-flex align-items-center">

            <div class="container">
                <div class="row">
                    <div class="col-lg-7 d-flex flex-column justify-content-center">
                        <h1 data-aos="fade-up">Digital Cloud Smart Card In Minutes</h1> <br />
                        <h4 data-aos="fade-up" data-aos-delay="400">A Digital Business Card that encompasses the entire business world for small to large businesses and entrepreneurs, specially designed for all types of enterprises to keep the business information flow to clients which would be a one stop card for all details. One can create a personal lifecycle data repository accessible 24/7 anywhere with just a click of a button. With high scalability capacity, using next generation technology like Cloud, AI and ML, to become an affordable cloud portal for any sector.
                            
                        </h4>
                        <div data-aos="fade-up" data-aos-delay="600">
                            <div class="row">
                                <div class=" col-lg-6 text-center text-lg-start">
                                    <a href="https://zangiecard.mounts.in/auth/signup" class="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span>CREATE A CARD</span>
                                    <i class="bi bi-arrow-right"></i>
                                </a>
                                </div>
                                <div class=" col-lg-6 text-center text-lg-start">
                                    <img src="https://mountsfileserver.azurewebsites.net/images/Zangie/AppGoogle.png" class="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 hero-img" data-aos="zoom-out" data-aos-delay="200">
                    <img src="https://mountsfileserver.azurewebsites.net/HTML/images/cards-sample.png" class="img-fluid" alt="" />
                    </div>
                </div>
                
            </div>
            





        </section>
        



        <main id="main">
            <section id="about" class="about">

                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">
                             
                                <h2>About us</h2>
                                <p>
                                  We provide simple and easy to use IT solutions to solve the most complicated business problems. We, as an organization, firmly believe that size is never an obstacle to success. Provided one has the perfect mix of manpower, equipment and resources to capitalize on opportunities, which is Exactly what Team Mounts can help you to achieve. Boosting your productivity and profits. Cutting down costs, time and wastage. By streamlining your operations and better utilizing your assets. </p>
                                
                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/features-2.png" class="img-fluid" alt="" />
          </div>

                        </div>
                </div>


                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">
                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/values-1.png" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">
                            
                                <h2>Business Focus</h2>
                                <p>
                                    Our primary business focus and core competency is to provide customers with End-to-End Solutions in the area of emerging technologies, designed on the world class platforms, thereby empowering enterprises technologically for the new millennium. Mounts also aims to dispel the myth that such solutions are meant for large corporations only, which can invest millions for such a solution. Pricing our products reasonably, we intend to take it to the masses for bulk consumption.</p>
                            </div>
                        </div>

                        

                    </div>
                  
                </div>

            </section>

            <section id="features" class="features">

                <div class="container" data-aos="fade-up">

                    <header class="section-header">
                       
                        <p>Features</p>
                    </header>

                    <div class="row">

                        <div class="col-lg-6">
                            <img src="assets/img/features.png" class="img-fluid" alt="" />
                        </div>
                        

                            <div class="col-lg-6 mt-5 mt-lg-0 d-flex">
                                <div class="row align-self-center gy-4">

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                        <h3>Easily create digital business cards</h3>
                                        </div>
                                    </div>

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                        <h3>Share your cards with anyone</h3>
                                        </div>
                                    </div>

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                        <h3>Manage your contacts seamlessly</h3>
                                        </div>
                                    </div>

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                        <h3>Turn paper cards into digital contacts</h3>
                                        </div>
                                    </div>

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                            <h3>Message Customers</h3>
                                        </div>
                                    </div>

                                    <div class="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                                        <div class="feature-box d-flex align-items-center">
                                            <i class="bi bi-check"></i>
                                        <h3>Digital Marketing</h3>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    </section>

            <section id="services" class="services">

                <div class="container" data-aos="fade-up">

                    <header class="section-header">
                    
                        <p>Being A Zangien!</p>
                    </header>

                    <div class="row gy-4">

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div class="service-box blue">
                                <i class="fas fa-phone-square-alt icon"></i>
                                
                                <h3>Contact Information / Telephony</h3>
                                <p>Basic contact information details like organization name, business & service details, mobile number, city, state and address are updated.</p>
                              
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div class="service-box orange">
                                <i class="fab fa-whatsapp icon"></i>
                                <h3>What's App</h3>
                                <p>Separate number for whatsapp and ability to make direct whatsapp calls. The platform creates a separate link for whatsapp.</p>
                            
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div class="service-box green">
                                <i class="fas fa-laptop icon"></i>
                                <h3>Dynamic Website</h3>
                                <p>One can dynamically link a website to the card. One can create many cards linking to many websites and also create dynamic content.</p>
                               
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
                            <div class="service-box red">
                             <i class="fas fa-share-alt icon"></i>
                                <h3>
                                    Social Media Links</h3>
                                <p>Cards can be shared across multiple social platforms. Social media specific cards can be created and managed.</p>
                                
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
                            <div class="service-box purple">
                            
                                <i class="fas fa-shopping-cart icon"></i>
                                <h3>
                                    Product Catalogues</h3>
                                <p>Cards are capable of displaying product information and can be used as an e-commerce platform. Product specific cards can be generated.</p>
                                
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="700">
                            <div class="service-box pink">
                                
                                <i class="fas fa-envelope icon"></i>
                                <h3>Email Address</h3>
                                <p>Cards can have email id which can be easily shared across different platforms. Email id can be dynamically updated.</p>
                                
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
                            <div class="service-box blue">
                                <i class="fab fa-cc-amazon-pay icon"></i>

                                <h3>
                                    Payment Links</h3>
                                <p>Different payment domain links can be updated in the card. Customers can get instant payment link related information.</p>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
                            <div class="service-box orange">
                                <i class="far fa-bell icon"></i>
                                <h3>Get Notified</h3>
                                <p>One can get notified when someone views the card. And get complete details as soon as someone saves the card.</p>

                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
                            <div class="service-box green">
                                <i class="far fa-id-card icon"></i>
                                <h3>Digital business cards</h3>
                                <p>Set up your entire team with on-brand digital business cards. More affordable and eco-friendly than paper business cards.</p>

                            </div>
                        </div>

                    </div>

                </div>

            </section>

            <Price />

        </main>

        

    </>);
  }
}
