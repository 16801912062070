import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class Productdetails extends Component {
    static displayName = Productdetails.name;

    constructor(props) {
        super(props);

        this.state = {
            toggle: true
        }

    }


    changehandle = () => {
        this.setState({
            toggle: !this.state.toggle
        })


    }

   
    render() {
        return (
            <>
            <section style={{ marginTop: 20 }} id="about" class="about">
                <header class="section-header">

                        <p> <img style={{ marginRight: 10 }} src="assets/img/pricing-free.png" class="img-fluid" alt="" />   FREE CARD KEEP</p>
                    <h5 >(Get Free Features)</h5>
                   
                    </header>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>
                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                <h2>Free Cloud Card</h2>
                                <p>
                                    Getting free Cloud Card Keep is simple when any one saves a card of already registered members Zangie Card. The system will ask for basic register information. Once it is verified one will get a free Card Keep.
                                </p>
                                

                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/details-2.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                </div>


                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">
                            <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img style={{ maxHeight: '500px' }}   src="assets/img/details-3.png" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                <h2>Card Keep</h2>
                                <p>
                                  The saved zangie card will be available in your free card keep. As soon as you save more Zangie cards, the same will be stored in the card keep. The free card keep will create a cloud platform for you. Which means no download is required and will be accessible from any smart device.</p>  </div>
                        </div>



                    </div>
                </div>

                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                <h2>Unlimited Cloud  Contacts</h2>
                                <p>
                                    One can save unlimited Contacts in Zangie Card Keep. Using the Zangie Card Keep you can directly make mobile and also whatsapp calls. You can also browse the details of the saved card.   </p>


                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img style={{ maxHeight: '500px' }}  src="assets/img/details-1.png" class="img-fluid" alt="" />
                        </div>

                    </div>
                    </div>


                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>

            </section>
            
                </>
        );
    }
}




