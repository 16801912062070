import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleNavbar2 = this.toggleNavbar2.bind(this);
    this.state = {
      collapsed: true,
      data: ''
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
      if (this.state.collapsed === true) {
          document.querySelector(".navbar").className = "navbar navbar-mobile"
          document.querySelector("i").className = "bi mobile-nav-toggle bi-x"
      }
      else {
          document.querySelector(".navbar").className = "navbar"
          document.querySelector("i").className = "bi bi-list mobile-nav-toggle"
      }

    }
    toggleNavbar2() {
        this.setState({
            collapsed: false
        });
      
            document.querySelector(".navbar").className = "navbar"
            document.querySelector("i").className = "bi bi-list mobile-nav-toggle"
       

    }


    componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 20) {
            document.querySelector(".header").className = "header fixed-top header-scrolled"
           
            
        } else {
            document.querySelector(".header").className = "header fixed-top";
           
        }

       
    };

  render () {
    return (
        <header id="header" class="header fixed-top header-scrolled">
            <div class="container-fluid container-xl d-flex align-items-center justify-content-between">

                <a href="#" class="logo d-flex align-items-center">
                    <img src="https://mountsfileserver.azurewebsites.net/HTML/images/logo.png" alt="" />
                
        <span>Smart Card</span>
                   

                </a>

                <nav id="navbar" class="navbar">
                    <ul>
                        <li><a onClick={this.toggleNavbar2}  class="nav-link scrollto" href="#hero">Home</a></li>
                        <li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="#about">About</a></li>
                        <li><a onClick={this.toggleNavbar2}  class="nav-link scrollto" href="#features">Features</a></li>
                        <li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="#services">Services</a></li>
                        <li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="#pricing">Pricing</a></li>
                        {/*<li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="https://zlogin.mounts.in/Phonenumber">Login</a></li>*/}
                        <li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="https://zangiecard.mounts.in/">Login</a></li>
                        <li><a onClick={this.toggleNavbar2} class="getstarted scrollto" href="https://zangiecard.mounts.in/auth/signup">CREATE A CARD</a></li>
                        {/*<li><a onClick={this.toggleNavbar2} class="nav-link scrollto" href="https://zlogin.mounts.in/Loginnew">Admin Login</a></li>*/}
                    </ul>
                    <i onClick={this.toggleNavbar} class="bi bi-list mobile-nav-toggle"></i>
                </nav>

            </div>
        </header>
    );
  }
}
