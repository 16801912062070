import React, { Component } from 'react';

import { Row , Col } from 'reactstrap';

export default class Price extends Component {
    static displayName = Price.name;

    constructor(props) {
        super(props);

        this.state = {
            toggle:true
        }

    }



    changehandle = () => {
        this.setState({
            toggle: !this.state.toggle
        })
     

    }
    render() {
        return (
            <section id="pricing" class="pricing">
                <div class="container" data-aos="fade-up">
                    <header class="section-header">
                        <h2>Pricing</h2>
                        <p>Check our Pricing</p>
                        <br />
                        <Row>
                            <Col>
                                <h3>Dollar <i class="fas fa-dollar-sign"></i></h3>
                            </Col>
                            <Col>
                                <label className="switch">
                                    <input type="checkbox" onChange={this.changehandle} />
                            <span className="slider round"></span>
                                </label>
                                </Col>
                            <Col >
                                <h3>Rupees <i class="fas fa-rupee-sign"></i></h3>
                                        </Col>
                        </Row>

                    </header>
                    <div class="row gy-4" data-aos="fade-left">

                        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="100">
                            <div class="box">
                                <h3 style={{ color: '#07d5c0' }} >{/*FREE USER*/}ZI CARD</h3>
                                {this.state.toggle ? <div class="price">9 points<span style={{ fontWeight: "900" }}> / <sup></sup>Free</span></div> : <div class="price">6 points<span style={{ fontWeight: "900" }}> / <sup></sup>Free</span></div>}
                                
                                <img src="assets/img/pricing-free.png" class="img-fluid" alt="" />
                                <ul>
                                    <li>Individual Card </li>
                                    <li>Cloud Contacts</li>
                                    <li>Device Independent </li>
                                    <li >All Media Friendly</li>
                                    <li >Card Templates</li>
                                    <li >Cloud Networking</li>
                                    <li >GlobalLinker</li>
                                    <li >Virtual Cyber Sphere</li>
                                    <li >AISculpt</li>
                                    <li >Smart Dashboard</li>
                                   
                                </ul>
                                <a href="https://zlogin.mounts.in/Register" class="btn-buy">Register</a>

                                <a style={{ marginTop: 20 }}  href="https://zangie.mounts.in/Productdetails" class="btn-buy2"> Product Details</a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="200">
                            <div class="box">
                                {/*<span class="featured">featured</span>*/}
                                <h3 style={{ color: '#65c600' }} >{/*GOLD CARD*/}GREEN CARD</h3>
                                {this.state.toggle ? <div class="price">15 points<span style={{ fontWeight: "900" }}> /<sup>$</sup> 1  </span></div> : <div class="price">18 points<span style={{ fontWeight: "900" }}> / <sup>₹</sup>90</span></div>}

                                <img style={{ maxHeight: '190px' }} src="assets/img/pricing-starter.png" clss="img-fluid" alt="" />
                                <ul>
                                    <li>Single Organization Card</li>
                                    <li>Organization Cloud Contacts</li>
                                    <li>Device Independent</li>
                                    <li>All Media Friendly</li>
                                    <li>Card Templates</li>
                                    <li>Organization Cloud Networking</li>
                                    <li>Organization Virtual Vantage</li>
                                    <li>Virtual Cyber Sphere </li>
                                    <li>AIDigitalMold</li>
                                    <li >Smart Dashboard</li>

                                </ul>
                                <a href={this.state.toggle ? "https://zlogin.mounts.in/Register?97" : "https://zlogin.mounts.in/Register?94"} class="btn-buy">Buy Now</a>
                                <a style={{ marginTop: 20 }} href="https://zangie.mounts.in/ProductGold" class="btn-buy2"> Product Details</a>

                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="300">
                            <div class="box">
                                {/*<span class="featured">featured</span>*/}
                               {/* <span class="featured"> H- Support</span>*/}
                                <h3 style={{ color: '#ff901c' }}>{/*PREMIUM CARD*/} GOLD CARD</h3>
                                {this.state.toggle ? <div class="price">81 points<span style={{ fontWeight: "900" }}> /<sup>$</sup> 5 </span></div> : <div class="price">50 points<span style={{ fontWeight: "900" }}> / <sup>₹</sup>225</span></div>}

                               <img src="assets/img/pricing-business.png" class="img-fluid" alt="" />
                                <ul>
                                    <li>MultiOrganization Card</li>
                                    <li>MultiOrganization Cloud Contacts</li>
                                    <li>Device Independent</li>
                                    <li>All Media Friendly</li>
                                    <li>Card Templates </li>
                                    <li>MultiOrganization Cloud connects</li>
                                    <li>MultiOrganization Virtual Vantage</li>
                                    <li>Virtual Cyber Sphere </li>
                                    <li  >AIDigitalMold</li>
                                    <li  >Smart Dashboard</li>

                                </ul>
                                <a href={this.state.toggle ? "https://zlogin.mounts.in/Register?96" : "https://zlogin.mounts.in/Register?95"} class="btn-buy">Buy Now</a>
                                <a style={{ marginTop: 20 }}  href="https://zangie.mounts.in/Productprimium" class="btn-buy2"> Product Details</a>

                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                            <div class="box">
                                <span class="featured"> H- Support</span>
                                <h3 style={{ color: '#ff0071' }}>{/*CORPORATE CARD*/}ZC CARD</h3>
                                <div class="price"> Customized</div> 
                                    <img src="assets/img/pricing-ultimate.png" class="img-fluid" alt="" />
                                <ul>
                                    <li>Customized Card</li>
                                    <li>Internal and Cloud Contacts</li>
                                    <li>Device Independent</li>
                                    <li>All Media Friendly </li>
                                    <li>Lifecycle Card </li>
                                    <li>B2b Cloud connects</li>
                                    <li>B2b Virtual Vantage</li>
                                    <li>Virtual Cyber Sphere</li>
                                    <li>AIDigitalMold</li>
                                    <li  >Smart Dashboard</li>

                                </ul>
                                <a href="https://zlogin.mounts.in/Register" class="btn-buy">Register</a>
                                <a style={{ marginTop: 20 }} href="https://zangie.mounts.in/ProductCorporate" class="btn-buy2"> Product Details</a>

                            </div>
                        </div>

                    </div>
                </div>

            </section>
        );
    }
}




