import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

export default class ProductCorporate extends Component {
    static displayName = ProductCorporate.name;

    constructor(props) {
        super(props);

        this.state = {
            toggle: true
        }

    }


    changehandle = () => {
        this.setState({
            toggle: !this.state.toggle
        })


    }

   
    render() {
        return (
            <>
            <section style={{ marginTop: 20 }} id="about" class="about">
                <header class="section-header">
                   
                        <p ><img src="assets/img/pricing-ultimate.png" class="img-fluid" alt="" /> CORPORATE CARD</p>
                 
                   
                    </header>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i  style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>
                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2> Card Specific</h2>
                                <p>
                                        Corporate Cards are specific to organization. Cards can be implemented in many ways depending upon the organization process. Like for schools the card can be used for a life cycle student ID Card. Means the student can have activity and different class marks sheets on cloud. When the student is promoted to the next class all the previous classes will be available on the cloud. Same can be referred to after 20/25 years also.

      </p>
                                

                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/aboutmain.jpg" class="img-fluid" alt="" />
                        </div>

                    </div>
                </div>


                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">
                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                                <img src="assets/img/hero.png" class="img-fluid" alt="" />
                        </div>
                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Employee Performance</h2>
                                <p>
                                        Similarly employee activity and performance details can be available on the card for life time. Smart dashboard will be available for different kinds of users based on the needs of the organisation.</p>  </div>
                        </div>



                    </div>
                </div>

                <div class="container" data-aos="fade-up">
                    <div class="row gx-0">

                        <div class="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
                            <div class="content">

                                    <h2>Platform Design</h2>
                                <p>
                                        It is just not a card, it can be evolved as a mini portal for any organisation. Based on the organization's needs the platform can be dedicatedly customized. So to implement we need to have a memorandum of understanding with the client.
 </p>


                            </div>
                        </div>

                        <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/img/features.svg" class="img-fluid" alt="" />
                        </div>

                    </div>
                    </div>
                    <div class="container">
                        <a href="https://zangie.mounts.in/#pricing">
                            <i style={{ color: '#012970' }} class="fas fa-arrow-circle-left fa-3x"></i>
                        </a>
                    </div>

            </section>
            
                </>
        );
    }
}




